export const json = {
    "locale": "fr",
    "logoPosition": "right",
    "focusFirstQuestionAutomatic": true,
    "completedHtml": {
     "fr": "<h3>Merci ! Un de nos partenaires vous contactera prochainement pour réaliser une étude personnalisée et gratuite de votre projet d'installation de panneaux solaires. Cette étude inclura également un devis gratuit, vous fournissant tous les détails nécessaires pour faire le meilleur choix pour votre maison. Nous sommes impatients de vous accompagner dans cette démarche vers une énergie plus verte et durable.</h3>"
    },
    "completedHtmlOnCondition": [
     {},
     {
      "expression": "{isMaison} = 0",
      "html": "Les panneaux solaires installés par nos partenaires sont uniquement destinés aux maisons individuelles."
     },
     {
      "expression": "{proprietaire} = 0",
      "html": "Il est nécessaire d'être propriétaire de la maison pour que nos partenaires puissent procéder à l'installation de panneaux solaires."
     }
    ],
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "radiogroup",
        "name": "proprietaire",
        "title": {
         "fr": "Vous êtes ?"
        },
        "isRequired": true,
        "choices": [
         {
          "value": "1",
          "text": {
           "fr": "Propriétaire"
          }
         },
         {
          "value": "0",
          "text": {
           "fr": "Locataire"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "isMaison",
        "title": {
         "fr": "Vous souhaitez des panneaux solaires pour :"
        },
        "isRequired": true,
        "choices": [
         {
          "value": "1",
          "text": {
           "fr": "Une maison"
          }
         },
         {
          "value": "0",
          "text": {
           "fr": "Un appartement"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "adresse",
        "title": {
         "fr": "Quelle est l'adresse de votre bien ?"
        }
       },
       {
        "type": "text",
        "name": "codePostal",
        "title": {
         "fr": "Quel est le code postal de votre logement ?"
        },
        "isRequired": true,
        "requiredErrorText": {
         "fr": "Merci de saisir un code postal valide"
        },
        "validators": [
         {
          "type": "regex",
          "text": {
           "fr": "Merci de saisir un code postal valide"
          },
          "regex": "^\\d+$"
         }
        ],
        "maxLength": 5
       },
       {
        "type": "text",
        "name": "prenom",
        "title": {
         "fr": "Votre prénom"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "nom",
        "title": {
         "fr": "Votre nom"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "mail",
        "title": {
         "fr": "Votre adresse mail"
        },
        "inputType": "email"
       },
       {
        "type": "text",
        "name": "telephone",
        "title": {
         "fr": "Votre numéro de téléphone pour vous contacter"
        },
        "isRequired": true,
        "requiredErrorText": {
         "fr": "Merci de saisir un numéro de téléphone valide"
        },
        "validators": [
         {
          "type": "regex",
          "text": {
           "fr": "Merci de saisir un numéro de téléphone valide"
          },
          "regex": "^[0-9 .-]+$"
         }
        ],
        "inputType": "tel"
       }
      ]
     }
    ],
    "triggers": [
     {
      "type": "complete"
     },
     {
      "type": "complete",
      "expression": "{isMaison} = 0"
     },
     {
      "type": "complete",
      "expression": "{proprietaire} = 0"
     }
    ],
    "cookieName": "formCookie",
    "sendResultOnPageNext": true,
    "showPrevButton": false,
    "showTitle": false,
    "showPageTitles": false,
    "showProgressBar": "top",
    "maxTextLength": 150,
    "completeText": {
     "fr": "Valider"
    },
    "questionsOnPageMode": "questionPerPage",
    "widthMode": "responsive",
    "fitToContainer": true
   }