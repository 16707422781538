import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
//import { surveyLocalization } from 'survey-core';
import "survey-core/i18n/french";
import "survey-core/defaultV2.min.css";
//import * as SurveyTheme from "survey-core/themes";
//import "./index.css";
import { json } from "./json";
//import { surveyLocalization } from "survey-core";
/* global fbq */



function SurveyComponent() {
    const survey = new Model(json);
    survey.applyTheme({
      "backgroundImage": "",
      "backgroundImageFit": "cover",
      "backgroundImageAttachment": "scroll",
      "backgroundOpacity": 1,
      "themeName": "borderless",
      "isPanelless": true,
      "colorPalette": "light",
      "cssVariables": {
          "--sjs-general-backcolor": "rgba(231, 240, 255, 1)",
          "--sjs-general-backcolor-dark": "rgba(220, 232, 252, 1)",
          "--sjs-general-backcolor-dim": "rgba(231, 240, 255, 1)",
          "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
          "--sjs-general-backcolor-dim-dark": "rgba(220, 232, 252, 1)",
          "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
          "--sjs-general-forecolor-light": "rgba(133, 154, 186, 1)",
          "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
          "--sjs-general-dim-forecolor-light": "rgba(133, 154, 186, 1)",
          "--sjs-primary-backcolor": "rgba(35, 101, 200, 1)",
          "--sjs-primary-backcolor-light": "rgba(35, 101, 200, 0.1)",
          "--sjs-primary-backcolor-dark": "rgba(26, 86, 175, 1)",
          "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
          "--sjs-base-unit": "8px",
          "--sjs-corner-radius": "4px",
          "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
          "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
          "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
          "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
          "--sjs-shadow-small": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
          "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
          "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
          "--sjs-shadow-inner": "inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
          "--sjs-shadow-inner-reset": "inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
          "--sjs-border-light": "rgba(220, 229, 241, 1)",
          "--sjs-border-default": "rgba(179, 200, 229, 1)",
          "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
          "--sjs-special-red": "rgba(229, 10, 62, 1)",
          "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
          "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-special-green": "rgba(25, 179, 148, 1)",
          "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
          "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-special-blue": "rgba(67, 127, 217, 1)",
          "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
          "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
          "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
          "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
          "--sjs-article-font-xx-large-textDecoration": "none",
          "--sjs-article-font-xx-large-fontWeight": "700",
          "--sjs-article-font-xx-large-fontStyle": "normal",
          "--sjs-article-font-xx-large-fontStretch": "normal",
          "--sjs-article-font-xx-large-letterSpacing": "0",
          "--sjs-article-font-xx-large-lineHeight": "64px",
          "--sjs-article-font-xx-large-paragraphIndent": "0px",
          "--sjs-article-font-xx-large-textCase": "none",
          "--sjs-article-font-x-large-textDecoration": "none",
          "--sjs-article-font-x-large-fontWeight": "700",
          "--sjs-article-font-x-large-fontStyle": "normal",
          "--sjs-article-font-x-large-fontStretch": "normal",
          "--sjs-article-font-x-large-letterSpacing": "0",
          "--sjs-article-font-x-large-lineHeight": "56px",
          "--sjs-article-font-x-large-paragraphIndent": "0px",
          "--sjs-article-font-x-large-textCase": "none",
          "--sjs-article-font-large-textDecoration": "none",
          "--sjs-article-font-large-fontWeight": "700",
          "--sjs-article-font-large-fontStyle": "normal",
          "--sjs-article-font-large-fontStretch": "normal",
          "--sjs-article-font-large-letterSpacing": "0",
          "--sjs-article-font-large-lineHeight": "40px",
          "--sjs-article-font-large-paragraphIndent": "0px",
          "--sjs-article-font-large-textCase": "none",
          "--sjs-article-font-medium-textDecoration": "none",
          "--sjs-article-font-medium-fontWeight": "700",
          "--sjs-article-font-medium-fontStyle": "normal",
          "--sjs-article-font-medium-fontStretch": "normal",
          "--sjs-article-font-medium-letterSpacing": "0",
          "--sjs-article-font-medium-lineHeight": "32px",
          "--sjs-article-font-medium-paragraphIndent": "0px",
          "--sjs-article-font-medium-textCase": "none",
          "--sjs-article-font-default-textDecoration": "none",
          "--sjs-article-font-default-fontWeight": "400",
          "--sjs-article-font-default-fontStyle": "normal",
          "--sjs-article-font-default-fontStretch": "normal",
          "--sjs-article-font-default-letterSpacing": "0",
          "--sjs-article-font-default-lineHeight": "28px",
          "--sjs-article-font-default-paragraphIndent": "0px",
          "--sjs-article-font-default-textCase": "none"
      }
  });
    survey.onComplete.add(function (sender, options) {
      // Display the "Saving..." message (pass a string value to display a custom message)
      options.showSaveInProgress();
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://sheetdb.io/api/v1/xnha514416l5l");
      //xhr.setRequestHeader("xc-auth", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InhjZWx3ZWJzZXJ2aWNlc0BnbWFpbC5jb20iLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6InVzX2F4bDczb3Z4MnBzczZ6Iiwicm9sZXMiOiJ1c2VyLHN1cGVyIiwidG9rZW5fdmVyc2lvbiI6IjQ1MjBkYjlmZmVkMjVmMWZhZTE5MDYwY2NiNzc5YjUzZjMwMzc0ZTRlZjAyNDZhNzBlNGE4YjE2MWJhNWVlYjFjNGYyMzI1NDQ3Nzc3ZWM2IiwiaWF0IjoxNzA0NTUzNzIwLCJleHAiOjE3MDQ1ODk3MjB9.Y2egWYXRbl9COsQu6SA4zWA9_DMuIuZcBSQfwcD6Iw4");
      xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
      xhr.onload = xhr.onerror = function () {
        if (xhr.status == 200) {
          // Display the "Success" message (pass a string value to display a custom message)
          options.showSaveSuccess();
          // Alternatively, you can clear all messages:
          // options.clearSaveMessages();
        } else {
          // Display the "Error" message (pass a string value to display a custom message)
          //options.showSaveError();
        }
      };
      xhr.send(JSON.stringify(sender.data));

    });
    return (<Survey model={survey} />);
}

export default SurveyComponent;